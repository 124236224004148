

















































































































import { Vue, Component, Prop, Watch, InjectReactive } from 'vue-property-decorator';
import { PageData } from '@/models/common/CommonModel';
import { WidgetData, WidgetModel } from '@/models/form/WidgetModel';
import SystemUtil from 'global-ui/packages/utils/SystemUtil';
import { Table } from 'vxe-table';
import { buildFormDataForSave, getCurrentDataSourceConfig } from '../utils';
import { WidgetDataSourceConfigTypeEnum, WidgetDataSourceTypeEnum } from '@/models/form/WidgetDataSourceModel';
import { getFormConfig, getWidgetDataSource } from '@/api/form-design/FormApi';
import i18n from '@/lang';

@Component({
  name: 'EleDialogList',
  components: {
    FormDataManager: () => import('@/components/form-data-manager/form-data-manager.vue')
  }
})
export default class extends Vue {
  @Prop({ default: () => SystemUtil.cloneDeep(WidgetData) }) widget!: WidgetModel;
  @Prop({ default: null }) value!: any;
  @Prop({ default: 'medium' }) size!: string;
  @Prop({ default: i18n.t('lang_configure_default_values') }) placeholder!: string;
  @Prop({ default: () => SystemUtil.cloneDeep(PageData) }) pageInfo!: any;
  @Prop({ default: null }) triggerGroupId!: any;
  @Prop({ default: false }) disabled!: boolean;
  @Prop({ default: true }) searchable!: boolean;

  @InjectReactive() flowId: any;
  @InjectReactive() formId: any;
  @InjectReactive() formDataId: any;
  @InjectReactive() formWidgetsValueMap: any;

  tableColumns: any[] = [];
  dataSource: any[] = [];
  tableData: any[] = [];
  displayText: string = '';
  dialogVisible: boolean = false;
  tableLoading: boolean = false;
  tablePage: any = {
    pageIndex: 1,
    pageSize: 10,
    total: 0
  };
  tableConfig: any = {
    columnKeys: [],
    htmlKeys: [],
    visibleKey: '', // 结果显示项
    valueKey: ''
  };
  selectData: any[] = [];

  formDataDialogVisible: boolean = false; // 表单数据源项管理页面
  curForm: any = {};

  searchColumns: any[] = [];
  searchParams: any = {};
  selectedList: any[] = [];
  /**
   * 是否可以管理选中的数据源项
   */
  get _formDataSourceConfigurable() {
    if (this.widget && this.widget.dataSource) {
      if (this.widget.dataSource.dataSourceConfig) {
        if (this.widget.dataSource.dataSourceConfig.type == WidgetDataSourceConfigTypeEnum.FORM_CONFIG) {
          return this.widget.dataSource.dataSourceConfig.configurable;
        }
      }
    }
    return false;
  }

  get properties() {
    return this.widget.properties;
  }

  //当前节点
  get _activedNode() {
    return this.$store.getters.activedNode;
  }
  created() {
    this.initData();
  }

  initData() {
    // 构造表格标题
    this.tableLoading = true;
    this.buildTableColumn(); // 构建表头
    this.buildTableConfig(); // 构建表格配置（多选、树）
    this.buildDisplay();
    if (this.pageInfo && this.pageInfo.list && this.pageInfo.list.length) {
      this.buildTableData(this.pageInfo); // 构建表格数据
    }
    this.tableLoading = false;
  }

  /**
   * 构造回信息
   */
  buildDisplay() {
    if (this.value && this.value.length && this.tableConfig.visibleKey) {
      // 回显初始值
      let displayTextArr: any[] = [];
      this.value.forEach(item => {
        displayTextArr.push(item[this.tableConfig.visibleKey]);
      });
      this.displayText = displayTextArr.join(',');
    } else {
      this.displayText = '';
    }
  }

  /**
   * 构建表格表头
   * @param {void} null
   * @returns void
   */
  buildTableColumn() {
    let curDataSourceConfig = getCurrentDataSourceConfig(this.widget);
    let dataSourceColumns = curDataSourceConfig?.columnConfig.customComplexColumns;
    if (dataSourceColumns && dataSourceColumns.length) {
      dataSourceColumns.sort((x, y) => {
        return x.index - y.index;
      });
      let tableColumns: any[] = [];
      let searchColumns: any[] = [];
      dataSourceColumns.forEach(item => {
        if (item.display == true) {
          tableColumns.push(item);
        }
        if (item.search == true) {
          searchColumns.push(item);
        }
      });
      this.tableColumns = tableColumns;
      this.searchColumns = searchColumns;
    } else {
      this.tableColumns = [];
      this.searchColumns = [];
    }
  }

  /**
   * 构建表格配置
   * @param {number} param
   * @returns {number}
   */
  buildTableConfig() {
    let curDataSourceConfig = getCurrentDataSourceConfig(this.widget);
    if (curDataSourceConfig?.columnConfig.customComplexColumns) {
      curDataSourceConfig?.columnConfig.customComplexColumns.forEach(item => {
        if (item.display) {
          this.tableConfig.columnKeys.push(item.key);
        }
        if (item.html) {
          this.tableConfig.htmlKeys.push(item.key);
        }
        if (item.visible) {
          this.tableConfig.visibleKey = item.key;
        }
        if (item.val) {
          this.tableConfig.valueKey = item.key;
        }
      });
    }
  }
  /**
   * 选择框勾选的事件
   */
  checkboxChange(val) {
    this.selectedList = [...val.records, ...val.reserves];
  }
  /**
   * 构建表格数据
   * @param {number} param
   * @returns {number}
   */
  buildTableData(pageInfo) {
    let result: any[] = [];
    this.tablePage = {
      pageIndex: pageInfo.pageNum,
      pageSize: pageInfo.pageSize,
      total: pageInfo.total
    };
    let tableData = pageInfo.list;
    tableData.forEach(row => {
      let item = {};
      row.columns.forEach(column => {
        item[column.key] = column.value;
      });
      result.push(item);
    });
    this.tableData = result;
    this.$nextTick().then(() => {
      this.echoSelectedData(this.selectedList);
    });
  }

  handleSearch() {
    this.changePage(this.tablePage);
  }

  handleReset() {
    this.searchParams = {};
    this.changePage(this.tablePage);
  }

  /**
   * 表格分页
   * @param {number} param
   * @returns {number}
   */
  changePage(pageInfo) {
    let params: any = {
      formId: this.formId,
      formModelId: this.widget.id,
      triggerGroupId: this.triggerGroupId,
      formModelDataList: buildFormDataForSave(this.formWidgetsValueMap, this.formDataId).filter(a => a.formModelCode),
      page: {
        pageIndex: pageInfo.currentPage,
        pageSize: pageInfo.pageSize
      }
    };

    if (this._activedNode && this._activedNode.id) {
      params.arguments = {
        var_current_node_id: this._activedNode.id,
        var_process_id: this.flowId
      };
    }
    if (this.formDataId) {
      params.formDataId = this.formDataId;
    }
    if (Object.keys(this.searchParams).length) {
      params.searchParams = this.buildSearchParams();
    }
    getWidgetDataSource(params).then((res: any) => {
      if (res.code == '1' && res.data) {
        let complexDataSource: any[] = res.data.complexDataList || [];
        complexDataSource.forEach(dataItem => {
          this.buildTableData(dataItem.pageInfo); // 构建表格数据
        });
      } else {
        this.$message.error(res.message);
      }
    });
  }

  /**
   * 构建搜索参数
   */
  buildSearchParams() {
    let result: any[] = [];
    if (Object.keys(this.searchParams).length) {
      for (const paramKey in this.searchParams) {
        let paramValue = this.searchParams[paramKey];
        if (paramValue) {
          result.push({ key: paramKey, value: paramValue });
        }
      }
    }
    return result;
  }

  /**
   * 显示弹出框
   * @param {number} param
   * @returns {number}
   */
  showDialog() {
    if (this.disabled) return;
    this.dialogVisible = true;
    this.selectedList = this.value;
    this.$nextTick().then(() => {
      this.echoSelectedData(this.selectedList);
    });
  }

  echoSelectedData(selectedValue) {
    let tableInst = this.$refs.tableRef as Table;
    if (tableInst) {
      if (selectedValue && selectedValue.length) {
        let selectKeys = selectedValue.map(item => {
          return item[this.tableConfig.valueKey];
        });
        if (this.properties.multiple) {
          let rows: any = [];
          selectKeys.forEach(selectItem => {
            let row = tableInst.getRowById(selectItem);
            rows.push(row);
          });
          tableInst.clearCheckboxRow();
          tableInst.setCheckboxRow(rows, true);
        } else {
          let row = tableInst.getRowById(selectKeys[0]);
          tableInst.setRadioRow(row);
        }
      } else {
        tableInst.clearRadioRow();
        tableInst.clearCheckboxRow();
      }
    }
  }

  /**
   * 点击确定按钮，保存数据
   * @param {number} param
   * @returns {number}
   */
  handleSave() {
    let result: any = [];
    if (this.properties.multiple) {
      let selectData = (this.$refs.tableRef as Table).getCheckboxRecords(true);
      if (selectData && selectData.length) {
        result = selectData.map(item => {
          delete item._XID;
          return item;
        });
      }
    } else {
      let selectData = (this.$refs.tableRef as Table).getRadioRecord();
      if (selectData) {
        delete selectData._XID;
        result.push(selectData);
      }
    }
    if (this.selectedList && this.selectedList.length) {
      this.selectData = this.selectedList;
      let displayTextArr: any[] = [];
      this.selectedList.forEach(item => {
        displayTextArr.push(item[this.tableConfig.visibleKey]);
      });
      this.displayText = displayTextArr.join(',');
      let value = {
        dataValue: this.selectedList,
        modelValue: this.displayText
      };
      this.$emit('input', this.selectedList);
      this.$emit('change', value);
      this.dialogVisible = false;
    } else {
      let value = {
        dataValue: [],
        modelValue: ''
      };
      // this.$message('请选择数据');
      this.$emit('input', []);
      this.$emit('change', value);
      this.dialogVisible = false;
    }
  }

  /**
   * 关闭弹出框
   * @param {number} param
   * @returns {number}
   */
  closeDialog() {
    this.dialogVisible = false;
  }
  resetValue() {
    (this.$refs.tableRef as any).clearCheckboxRow();
    /*   this.displayText = '';
    this.$emit('input', []); */
  }
  /**
   * 打开管理选项的弹出框
   */
  openFormDataDialog() {
    let params = {
      code: this.widget.dataSource.dataSourceConfig.formCode
    };
    getFormConfig(params).then(res => {
      this.curForm = { id: res.data.id, code: res.data.code, belongFormId: this.formId };
      this.formDataDialogVisible = true;
    });
  }

  /**
   * 修改完选项，关闭弹窗时
   */
  handleConfirm() {
    // this.changePage(this.tablePage);
    this.formDataDialogVisible = false;
    let params: any = {
      formId: this.formId,
      formModelId: this.widget.id,
      triggerGroupId: this.triggerGroupId,
      formModelDataList: buildFormDataForSave(this.formWidgetsValueMap, this.formDataId).filter(a => a.formModelCode),
      page: {
        pageIndex: this.tablePage.currentPage,
        pageSize: this.tablePage.pageSize
      }
    };
    if (this._activedNode && this._activedNode.id) {
      params.arguments = {
        var_current_node_id: this._activedNode.id,
        var_process_id: this.flowId
      };
    }
    getWidgetDataSource(params).then((res: any) => {
      if (res.code == '1' && res.data) {
        let complexDataList: any[] = res.data.complexDataList || [];
        complexDataList.forEach(item => {
          if (item.formModelCode == this.widget.compCode) {
            // this.widget.dataSource.pageInfo = item.pageInfo || [];
            this.$emit('changeDataSource', item.pageInfo);
            this.formDataDialogVisible = false;
          }
        });
      }
    });
  }

  @Watch('pageInfo', { deep: true })
  widgetWatcher(val) {
    if (val && val.list && val.list.length) {
      this.tableLoading = true;
      this.buildTableData(val); // 构建表格数据
      this.tableLoading = false;
    }
  }

  @Watch('value', { deep: true })
  valueWatcher(val) {
    this.buildDisplay();
  }
}
