import { WidgetModel } from './WidgetModel';

export const WidgetActionTypeEnum = {
  COMPONENTS: 'COMPONENTS',
  LINK: 'LINK',
  API: 'API',
  LOAD: 'LOAD'
};

export const WidgetEventTypeEnum = {
  SINGLE_CLICK: 'SINGLE_CLICK',
  ON_FOCUS: 'ON_FOCUS',
  ON_BLUR: 'ON_BLUR',
  KEY_ENTER: 'KEY_ENTER',
  OVER: 'OVER',
  VALUE_CHANGE: 'VALUE_CHANGE'
};

export interface WidgetTriggerModel {
  name: string;
  type: string;
  affectedComponents?: WidgetModel[];
  url?: string;
  target?: string;
  params?: any;
  [key: string]: any;
}

export const WidgetTriggersData: WidgetTriggerModel[] = [
  {
    name: '组件关联',
    type: 'COMPONENTS',
    affectedComponents: []
  },
  {
    name: '打开链接',
    type: 'LINK',
    url: 'https://www.baidu.com',
    target: '_blank',
    outerPage: false
  },
  {
    name: '调用接口',
    type: 'API',
    url: 'https://www.baidu.com',
    param: '{}'
  }
];

export interface WidgetTriggerConditionModel {
  code: any;
  key: string;
  source: string;
  value: unknown;
  result: unknown;
  filter: { dataPoolType: string; truthType: string; type: string };
  compare: string;
  logical: string;
  first: boolean;
  dataSource: any[];
  conditions?: any[];
}

export const WidgetTriggerConditionData: WidgetTriggerConditionModel = {
  code: {
    type: '',
    value: null
  },
  key: '',
  source: 'SELECT',
  value: null,
  result: null,
  filter: { type: 'STRING', dataPoolType: '', truthType: '' },
  compare: '',
  logical: 'AND',
  first: true,
  conditions: [],
  dataSource: []
};

export interface WidgetTriggerConditionGroupModel {
  key: string;
  logical: string;
  conditions: WidgetTriggerConditionModel[];
  first: boolean;
}

export var WidgetTriggerConditionGroupData: WidgetTriggerConditionGroupModel = {
  key: 'group1',
  logical: 'AND',
  conditions: [],
  first: true
};

export interface WidgetTriggerGroupsModel {
  conditionGroups: WidgetTriggerConditionGroupModel[];
  triggers: any[];
  [key: string]: any;
}

export const WidgetTriggerGroupsData: WidgetTriggerGroupsModel = {
  conditionGroups: [],
  triggers: WidgetTriggersData
};

export interface WidgetActionModel {
  title: string;
  name: string;
  type: string;
  groupType: string;
  triggerGroups: WidgetTriggerGroupsModel[];
  sort: number;
  [key: string]: any;
}

export const WidgetActionData: WidgetActionModel = {
  title: '',
  name: '',
  type: '',
  groupType: '',
  triggerGroups: [],
  sort: 0
};
